.chart {
  margin: 0px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.chartTitle {
  margin-bottom: 20px;
}

table.tCharts {
  width: 100%;
  border: 0px;
  padding: 0px;
}
td.tCharts {
  border: 0px;
  padding: 0px;
  
}