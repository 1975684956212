
img.custom_banner {
  max-width: 1216px;
  width: auto;
  padding:40px;
}
.div_banner{
  max-width: 1216px;
  max-height: 256px;
  height: auto;
  width: auto;
  padding:20px;
}
.div_home_Container {

  margin-top: 10px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;

}
.datePicker{
    font-size: 14px;
    align-items: right;
    justify-content:right;
    text-align: right;
    display: inline;
    float: right;
    padding:0px 30px 0px 0px !important;
}
.column{
    display: inline;
    float: right;
    font-size:14px;
    font-family:"Manrope";
    font-weight:700;
    padding: 5.5px;
    margin-right: 10px;
    color:#666666;
}
.labelPeriodo{
    font-size:14px;
    font-family:"Manrope";
    font-weight:700;
    color:#666666;
    justify-content:center;
    align-items: center;
    margin-top: 50px;

}

input[type="date"], 
input[type="time"] {
    width: 100px !important;
    height: 30px;
    margin-right: 5px;
    padding-left:5px;
    border: 0.5px solid;
    border-color: #999999;
    border-radius: 3px;
    font-size:14px;
    font-family:"Manrope";
    color:#666666;
}
input[type="radio"]{
  accent-color: #FF3131;
}

  .div_home_custom{
    display:grid;
    min-width: 350px;
    padding: 40px;
    border-radius: 24px;
    border: 1px solid var(--opacity-8, rgba(0, 0, 0, 0.08));
    background: var(--Greys-White, #FFF);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  }

  .div_custom_Quadro {
    padding: 5px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    width: 90%;
  }
  .tableSpace {
    border-collapse: separate;
    border-spacing: 40px;
    border:0px; 
  }
  .table{
    border:0;
    justify-content: left;
    align-items: left;
    width: 100%;
  }
  .left {
    font-size: 16px;
    font-weight:700;
    color:#999999;
    line-height: 24px;

  }
  .right {
    font-size: 24px;
    font-weight:700;
    color: #EE8253;
    line-height: 32px;;
    text-align: right;
    justify-content: right;
  }
  .details { 
    font-size: 16px;
    font-weight:700;
    color: #EE8253;
    margin-top: 32px;
    justify-self: center;
  }
  .labelTitle {
    text-align: left;
    font-size: 24px;
    font-weight:700;
    line-height: 32px;
    color: black;
  }
  .labelMsgData {
    text-align: left;
    font-size: 14px;
    font-weight:700;
    line-height: 24px;
    color:#999999;
  }
  .labelMsgDataAtivos {
    text-align: left;
    font-size: 10px;
    font-weight:700;
    line-height: 16px;
    color:#999999;
    padding: 0px;
  }  
  .labelDesc {
    text-align: left;
    padding: 0px 0px 0px 5px;
    font-size: 10px;
    font-weight:700;
    margin-top: 0px;
    color:#999999;
  }

  .button_custom_action{
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
    background-color: #EE8253;
    color: white;
    cursor: pointer;
    margin:0px;
    margin-top:10px;
    width:100px;
    font-size:14px;
    font-family:"Manrope";
    font-weight:700;
  }

  .icon{
    padding: 16px;
    width: 24px;
    height: 24px;
}