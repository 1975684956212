@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #EE8253; /* Blue */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    justify-content: center;
    align-items: center;
  }
  
  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }
  .div_custom {
    font-size: 16px;
    font-weight: 600;
    color: #EE8253;
  }