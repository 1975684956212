.featured {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.featuredItem {
  flex: 1;
  margin: 0px 5px;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  background-image:   linear-gradient(135deg, rgba(238,130,83,1) 0%, rgba(255,251,251,1) 50%);

}

.featuredTitle{
    font-size: 20px;
    text-align:center;
}

.featuredMoneyContainer{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.featuredMoney{
    font-size: 30px;
    font-weight: 700;
}

.featuredMoneyRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon{
    font-size: 14px;
    margin-left: 5px;
    color: green;
}

.featuredIcon.negative{
    color: red;
}

.featuredSub{
    font-size: 15px;
    color: gray;
}

table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
    margin-bottom: 16px;
  }
  
 td.labelStatus {
    text-align: left;
    padding: 0px 5px 0px 5px;
    font-size: 14px;
    font-weight:700;
    margin-top: 0px;
    color:#999999;
  }
  td.labelValue {
    text-align: left;
    padding: 3px 5px 5px 5px;
    font-size: 16px;
    font-weight:700;    
  }

  img.icon{
    color: #999999;
    padding: 5px 5px 0px 5px;
    width: 20px;
    height: 20px;
}