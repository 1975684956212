.custom-minigrid-size {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 4px;
    border: 1px solid #d7d8da;
    border-radius: 10px;
    width: 100%;
  }
  
  .custom-minigrid-size td {
    text-align: center;
    vertical-align: middle;
  }
  
  .custom-minigrid-size tbody td {
    border: 1px solid #d7d8da;
    border-radius: 10px;
    min-width: 40px;
    height: 40px;
    padding: 5px;
    background-color: #f2f2f2;
 
  }
  .custom-minigrid-no-value {
    background-color: inherit !important;
  }
  
  .custom-minigrid-size thead td {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
  }
  

  