.div_custom_ {
  flex: 4;
  justify-content: center;
  align-items: center;
}


.div_custom_Container {
  display: grid;
  margin-top: 10px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;

}
.div_custom_UpdateList {
  flex: 2;
  padding: 20px;
  border: 1px solid var(--opacity-8, rgba(0, 0, 0, 0.08));
  background: var(--Greys-White, #f9f9f9);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}
.div_custom_Form {
  flex: 2;
  padding: 20px;
  border: 1px solid var(--opacity-8, rgba(0, 0, 0, 0.08));
  background: var(--Greys-White, #f9f9f9);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  max-width: 80%;
  min-width: 800px;

  justify-self: center;
}

.div_custom_ShowTop {
  display: flex;
  align-items: center;
}

.div_custom_ShowImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.div_custom_ShowTopTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.div_custom_ShowUsername {
  font-weight: 600;
}

.div_custom_ShowUserTitle {
  font-weight: 300;
}

.div_custom_ShowBottom{
    margin-top: 20px;
}

.div_custom_ShowTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.div_custom_ShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}

.div_custom_ShowIcon{
    font-size: 16px !important;
}

.div_custom_ShowInfoTitle{
    margin-left: 10px;
}

.div_custom_UpdateTitle{
    font-size: 24px;
    font-weight: 600;
}

.div_custom_UpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.div_custom_UpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.div_custom_UpdateItem>label{
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
}

.div_custom_UpdateInput{
    border: none;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
}

.div_custom_UpdateInput_textArea{
  border: none;
  width: 100%;
  height:100px;
  border-bottom: 1px solid gray;
  margin-bottom: 10px;
  font-family: inherit;
}
.div_custom_UpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.div_custom_UpdateLeft{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.div_custom_UpdateUpload{
    display: flex;
    align-items: center;
}

.div_custom_UpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
}

.div_custom_UpdateIcon{
    cursor: pointer;
}

.div_custom_UpdateButton{
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: darkblue;
    color: white;
    font-weight: 600;
    width:150px;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(0.8); /* IE */
  -moz-transform: scale(0.8); /* FF */
  -webkit-transform: scale(0.8); /* Safari and Chrome */
  -o-transform: scale(0.8); /* Opera */
  transform: scale(0.8);
  padding: 10px;
}

.div_custom_UpdateCheck{
  flex-direction: row;
  margin-top: 0px;
}

.div_custom_Gender > input {
  margin-top: 15px;
}

.div_custom_Gender>label{
    margin: 10px;
    font-size: 18px;
    color: #555;
}
.div_custom_Select{
  height: 30px;
  border-radius: 5px;
}

.list{
  width:100%;
  padding:5px;
}

.div_custom{
  flex: 4;
}
.container_custom {
  display: flex;
  margin-top: 20px;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}
.div_form {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 20px;
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.showTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}
.button_custom_lst{
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  background-color: #EE8253;
  color: white;
  width:60px;
}
.button_custom_lst_delete{
border: none;
border-radius: 10px;
padding: 5px 15px;
background-color: red;
color: white;
cursor: pointer;
margin-right: 20px;
}
.button_custom_import{
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  background-color: #EE8253;
  color: white;
  cursor: pointer;
  margin:0px;
  margin-top:10px;
  width:200px;
}
.button_custom_action{
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  background-color: #2dd36f;
  color: white;
  cursor: pointer;
  margin:0px;
  margin-top:10px;
  width:200px;
}
.button_custom_action_add_top{
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  background-color: #EE8253;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  width:180px;
}
.button_custom_status{
  border-radius: 10px;
  border: 2px solid;
  background-color: white;
  color: #EE8253;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  border-color: #EE8253;
  width:150px;
}
.button_custom_status_finalizado{
  border-radius: 10px;
  border: 2px solid;
  background-color: white;
  color: green;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  border-color: green;
  width:150px;
}

.div_data_grid{
  height: 400px;
  width: 100%;
  margin-bottom:10px;
}
input.largerCheckbox {
  width: 25px;
  height: 25px;
}
.dataGridMuiHeader{
  background-color:#F2F5F7;
  border-radius: 5px;
}