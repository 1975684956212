
.logoimg {
  width: 150px;
  align-items: center;
  justify-content:center;

}
.center {
  text-align: center;
  justify-content:center;
  border: 0px;
}
.right {
  text-align: center;
  display: flex;
}
.tableBorder{
  border: 0px;
}