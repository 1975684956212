
header {
  grid-area: header;
  padding: 5px;
  align-items: center;
  justify-content:center;
  background-color:white;
  border:0px;
  height:55px;
}

article {
  grid-area: content;
  padding: 0px 5px 5px 5px;
  background-color:#f9f9f9;
  justify-content: center;
  align-items: center;
  border-radius:10px;;
}
aside {
  grid-area: sidebar;
  background-image:"linear-gradient( to right, #FF3131, #FF914C )";
  height: 100%;
}
footer {
  padding: 20px;
  grid-area: footer;
  background-color: #f6deca;

}
.wrapper {
  display: grid;
  grid-template-columns: 0.3fr 3fr;
  grid-template-areas: 
    "sidebar  header"
    "sidebar content"
    "footer  footer";
}
/* @media (max-width: 500px) {
  .wrapper {
    grid-template-columns: 4fr;
    grid-template-areas:
      "header" "account"
      "content"
      "sidebar"
      "footer";
  } 
}*/